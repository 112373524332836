<template>
  <cw-page
    icon="warning"
    class="application__rejected"
  >
    <v-row>
      <v-col>
        <p>
          <translate>
            The loan application has been interrupted because unfortunately we are unable to
            grant the loan at this time.
          </translate>
        </p>
        <p>
          <translate>
            There are ambiguities in the company's signing rights. The matter must be clarified with
            the assistance of the Patent and Registration Office. A new loan application can be
            made once the signing rights have been clarified. If you have any questions, you can
            contact our customer support.
          </translate>
        </p>
      </v-col>
    </v-row>
  </cw-page>
</template>

<script>
export default {
  name: 'CwbusinessLoanProcurationAbstractionRejection',
};
</script>
